<template>
  <div id="m-pdf">
    <div id="con" style="margin-top: 20px;">
      <span style="font-size: 14px; padding: 5px 10px;background-color: #318bfc;color: #fff;"  @click="$router.go(-1)">返回</span>
      <div id="pdf-content" style="margin-top: 15px;">
    </div>
    </div>
  </div>
</template>
<script>
// import pdf from 'vue-pdf'
// import { protocolGet } from "../../../api/validation/shareagreement";//调用的接口
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
 
  // components: {
  //   pdf
  // },
  props: {},
  data () {
    return {
      // title: this.$route.meta?.title || '',
      pdfh5: null,
      pdfUrl: '',
      pageNum: 1
    }
  },
  watch: {},
  computed: {},
  methods: {
    getprotocolGet () {
      // protocolGet().then((res)=>{
      //   if(res.code==200){
      // this.pdfUrl = '/air.pdf'//获取到的协议展示
      this.pdfh5 = new Pdfh5("#pdf-content", {
        pdfurl: this.pdfUrl,
        goto: 1,
        // 设置每一页pdf上的水印
        // logo: { src: require("@/assets/images/bus/icon_head@2x.png"), x: 420, y: 700, width: 120, height: 120 },
      });
      // this.pdfh5.scrollEnable(true); // 允许pdf滚动
      // 监听pdf准备开始渲染，此时可以拿到pdf总页数
      // this.pdfh5.on("ready", function () {
      // console.log("总页数:" + this.totalNum);
      // });
      // 监听pdf加载完成事件，加载失败、渲染成功都会触发
      this.pdfh5.on("complete", (status, msg, time) => {
        console.log("状态:" + status + "，信息:" + msg + "，耗时:" + time + "毫秒");
      });
    },
    // getPageNum () {
    //   let loadingTask = pdf.createLoadingTask(this.pdfUrl, { withCredentials: false })
    //   loadingTask.promise.then(pdf => {
    //     this.pageNum = pdf.numPages
    //   }).catch(err => {
    //     console.error('pdf加载失败', err);
    //   })
    // },
    // sign () {
    //   this.$router.push({
    //     path: '/xxx',
    //   })
    // },
  },
  created () {
    this.pdfUrl=localStorage.getItem('pdfName')+'.pdf'
  },
  mounted () {
    this.getprotocolGet()
  },
}
</script>
<style lang="scss" scoped>
.m-pdf {
  // 保证pdf区域铺满整个屏幕
  // 方法1：使用vw和vh视窗单位，1vw=视窗宽度的1%；1vh=视窗高度的1%
  // width: 100vw;
  // height: 100vh;
  // 方法2：使用fixed定位
  position: fixed;
  top: 15px;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
